import React, { FC, useState } from 'react'
import { PhoneNumber } from 'libphonenumber-js'
import { LinkButton } from '@/atoms/Button'
import { FlexRow } from '@/atoms/FlexContainers'
import { RequestShowtimesModal } from '@/organisms/RequestShowtimesModal'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface ShowtimesWithNoUrlsStateProps {
  theatricalSlug: string
  venue: TheatricalShowtimeVenue
  venuePhoneNumber: PhoneNumber | undefined
  analyticsPayload: {
    venue: TheatricalShowtimeVenue
    latitude: number | undefined
    longitude: number | undefined
    theatricalName: string
    theatricalSlug: string
    projectSlug: string
  }
}

const EmptyShowtimesState: FC<ShowtimesWithNoUrlsStateProps> = ({ analyticsPayload, venuePhoneNumber }) => {
  const { t } = useTranslate('tickets')
  const [showRequestShowtimesModal, setShowRequestShowtimesModal] = useState(false)
  const { track } = useSafeAnalytics()

  const closeModal = () => {
    setShowRequestShowtimesModal(false)
  }

  return (
    <div className="my-2 block w-full justify-between">
      <FlexRow className="mt-4 flex-wrap gap-2">
        {!!venuePhoneNumber && (
          <LinkButton
            href={venuePhoneNumber.getURI()}
            variant="black"
            onClick={() => {
              track('Tickets Venue Phone Number Clicked', analyticsPayload)
            }}
          >
            {t('callVenueNumber', 'Call: {{phoneNumber}}', { phoneNumber: venuePhoneNumber.formatNational() })}
          </LinkButton>
        )}
      </FlexRow>
      <RequestShowtimesModal
        showModal={showRequestShowtimesModal}
        onClose={closeModal}
        analyticsPayload={analyticsPayload}
      />
    </div>
  )
}

export default EmptyShowtimesState
