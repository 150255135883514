import React from 'react'
import { PhoneNumber } from 'libphonenumber-js'
import { ExternalLink } from '@/atoms/ExternalLink'
import { ParagraphSM } from '@/atoms/Text'
import { Paragraph2XS } from '@/atoms/Typography'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface ShowtimesWithNoUrlsStateProps {
  venue: TheatricalShowtimeVenue
  venuePhoneNumber: PhoneNumber | undefined
  analyticsPayload: {
    venue: TheatricalShowtimeVenue
    latitude: number | undefined
    longitude: number | undefined
    theatricalName: string
    theatricalSlug: string
    projectSlug: string
  }
}
const ShowtimesWithNoUrlsState = ({ venue, venuePhoneNumber, analyticsPayload }: ShowtimesWithNoUrlsStateProps) => {
  const { t } = useTranslate('tickets')
  const { track } = useSafeAnalytics()
  return (
    <div>
      <ParagraphSM color="black" weight="semibold" className="my-4">
        {t(
          'onlineTicketSalesNotAvailable',
          'Online ticket sales are not available at this theater. Contact this theater for ticket sales.',
        )}
      </ParagraphSM>
      {!!venuePhoneNumber && (
        <ExternalLink
          className="my-2 block xl:hidden"
          href={venuePhoneNumber.getURI()}
          onClick={() => {
            track('Tickets Venue Phone Number Clicked', analyticsPayload)
          }}
        >
          <Paragraph2XS className="text-gray-900 underline xl:text-right">
            {venuePhoneNumber.formatNational()}
          </Paragraph2XS>
        </ExternalLink>
      )}
      {!!venue.url && (
        <ExternalLink
          className="my-2 block xl:hidden"
          href={venue.url}
          onClick={() => {
            track('Tickets Venue Website Clicked', analyticsPayload)
          }}
        >
          <Paragraph2XS className="text-blue-500 underline xl:text-right">
            {t('visitWebsite', 'Visit Website')}
          </Paragraph2XS>
        </ExternalLink>
      )}
    </div>
  )
}

export default ShowtimesWithNoUrlsState
