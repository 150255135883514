import React, { FC, useMemo } from 'react'
import { FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { CaptionMD } from '@/atoms/Text'
import { AppFeatures, ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { CriticScore, TheatricalReleaseObject } from '@/types/codegen-federation'
import { GuildScoreToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/GuildScoreToolTip'

interface TicketMovieDetailsProps {
  duration: string | null
  theatricalRelease: TheatricalReleaseObject
  projectGuildScore: {
    guildScore: number | undefined
  }
}

const criticSvgs: Record<string, Record<string, string>> = {
  ['Rotten Tomatoes']: {
    ['Tomatometer']: '/v1688691818/angel-studios/misc-icons/rotten-tomatoe.svg',
    ['Audience']: '/v1688691818/angel-studios/misc-icons/popcorn.svg',
  },
}

const addSvgsToCriticScores = (companyName: string, scores: CriticScore[]) => {
  if (!scores) return []
  return scores?.map((score) => {
    const svg = criticSvgs[companyName ?? ''][score?.name ?? '']
    return { ...score, svg }
  })
}

const TicketMovieDetails: FC<TicketMovieDetailsProps> = ({ duration, theatricalRelease, projectGuildScore }) => {
  const critics = useMemo(() => {
    const rottenTomatoes = theatricalRelease?.critics?.find((critic) => critic?.company === 'Rotten Tomatoes')
    if (!rottenTomatoes) return []
    return [
      {
        ...rottenTomatoes,
        scores: addSvgsToCriticScores(rottenTomatoes?.company ?? '', rottenTomatoes?.scores as CriticScore[]),
      },
    ]
  }, [theatricalRelease?.critics])
  const guildScore = projectGuildScore?.guildScore
  return (
    <FlexRow className="relative z-[100] gap-2 text-core-gray-300">
      {duration && (
        <>
          <CaptionMD>{duration}</CaptionMD>
          <div className="h-1 w-1 rounded-full bg-core-gray-400" />
        </>
      )}
      {theatricalRelease?.movieRating && <CaptionMD className="uppercase">{theatricalRelease?.movieRating}</CaptionMD>}
      <GBFadeInExperiment defaultValue={false} experimentName={'tickets-guild-score' as keyof AppFeatures}>
        <ExperimentVariation variation={true}>
          <div className="flex items-center gap-2">
            <div className="h-1 w-1 rounded-full bg-core-gray-400" />
            {guildScore && (
              <div className="relative z-[999]">
                <GuildScoreToolTip
                  buttonClassName="!p-0 bg-black/10 p-1 bg-[#FFF] bg-opacity-20 border-0 !focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                  panelClassName="!z-[9999] !outline-none !top-[26px]"
                  guildScore={guildScore}
                />
              </div>
            )}
          </div>
        </ExperimentVariation>
      </GBFadeInExperiment>
      {critics?.map((critic) => {
        return critic?.scores?.map((criticScore) => {
          if (!criticScore?.value || criticScore.value < 70) return null

          return (
            <>
              <div className="h-1 w-1 rounded-full bg-white/20" />
              <FlexRow className="gap-0.5">
                {criticScore?.svg && (
                  <Image className="rounded-xl shadow-xl" alt="" src={criticScore.svg} width={20} height={20} />
                )}
                <CaptionMD className="uppercase text-white/70 md:text-white">{criticScore.value}%</CaptionMD>
              </FlexRow>
            </>
          )
        })
      })}
    </FlexRow>
  )
}

export default TicketMovieDetails
