import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { isSameDay, parseISO } from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { AsH3, CaptionMD, Text } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { slugs } from '@/constants/slugs'
import { useThemeContext } from '@/contexts/ThemeContext'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { getSortedShowtimes } from '@/services/TicketsService'
import { useUser } from '@/services/UserService'
import { TheatricalReleaseObject, TheatricalShowtime, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { useTranslate } from '@/utils/translate/translate-client'
import { VenueLogo } from '@/views/TicketCheckoutViews/ReservationSummaryView/components/VenueLogo'
import ShowtimesList from '@/views/TicketCheckoutViews/ShowtimesView/components/ShowtimesList'
import ShowtimesWithNoUrlsState from '@/views/TicketCheckoutViews/ShowtimesView/components/ShowtimesWithNoUrlsState'
import { useTicketsPromoContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/TicketsPromoContext'
import { LocaleWarningModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueList'
import VenuePills from '@/views/TicketCheckoutViews/ShowtimesView/components/VenuePills'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'
import { sanitizePhoneNumber } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { generateTrackingPayload } from '@/views/TicketCheckoutViews/utils'
import { useTicketsContext } from './TicketsContext'

const autoScrollToVenue = ({ venueId, venueRef }: { venueId: string; venueRef: React.RefObject<HTMLLIElement> }) => {
  const sessionVenueId = sessionStorage.getItem('venueId')
  if (sessionVenueId === venueId) {
    venueRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    sessionStorage.removeItem('venueId')
  }
}

export const VALID_PRIVATE_SHOWING_SLUGS = new Set([slugs.soundOfHopeTheStoryOfPossumTrot])

export interface VenueItemProps {
  venue: TheatricalShowtimeVenue
  query: ParsedUrlQuery
  theatricalRelease: TheatricalReleaseObject
  theaterSlug: string
  isGroupTicketsModalOpen?: boolean
  isBuyoutModalOpen?: boolean
  openGroupTicketsModal?: (type: GroupTicketsModalTypes) => void
  openBuyoutModal?: (buyoutVenue: TheatricalShowtimeVenue, theaterChain: string) => void
  setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>
  reservationId?: string
  zIndex: number
}

const regionToWarnWrongLanguage = new Set(['US', 'CA'])

export const VenueItem: FC<VenueItemProps> = ({
  venue,
  query,
  theaterSlug,
  theatricalRelease,
  openGroupTicketsModal,
  openBuyoutModal,
  reservationId,
  setLocaleWarningModalState,
  zIndex,
}) => {
  const { push } = useRouter()
  const { track } = useSafeAnalytics()
  const { trackProductAdded } = useEcommerceEvents()
  const { userLocation } = useUser()
  const venueRef = useRef<HTMLLIElement>(null)
  const { t } = useTranslate('tickets')
  const { isDarkMode } = useThemeContext()
  const { latitude, longitude } = userLocation?.coordinates ?? {}
  const { guildTicketsCount, handleLanguageWarning, trackTicketIntent, detectLanguageDifference } = useTicketsContext()
  const promoContext = useTicketsPromoContext()
  const isAngelPartnerVenue = venue.ticketingProviderSlug === 'atom'

  const sortedShowTimes = useMemo(() => getSortedShowtimes(venue.showtimes), [venue.showtimes])
  const venuePhoneNumber = useMemo(
    () => parsePhoneNumber(sanitizePhoneNumber(venue?.phone), { defaultCountry: 'US' }),
    [venue?.phone],
  )

  const doShowtimesHavePurchaseUrl = venue.showtimes.every((s) => !!s?.ticketUrl)
  const countryCode = theatricalRelease?.region?.countryCode ?? 'US'
  const isPhilippinesFreeTicketFlow = query?.promo && countryCode === 'PH'
  // const shouldShowFeaturedIcon = venue?.isFeatured && query.highlightFeatured
  const hasNoShowtimes = sortedShowTimes.length === 0
  const isVenueIntegrated = !!reservationId && !!theaterSlug && isAngelPartnerVenue

  const analyticsPayload = useMemo(() => {
    return {
      venue,
      latitude,
      longitude,
      theatricalName: theatricalRelease?.title as string,
      theatricalSlug: theatricalRelease?.theatricalSlug as string,
      projectSlug: theatricalRelease?.projectSlug as string,
    }
  }, [venue, theatricalRelease, latitude, longitude])

  const handleSendShowTimeClickEvents = useCallback(
    (showTime: TheatricalShowtime) => {
      const trackingPayload = generateTrackingPayload(showTime, analyticsPayload, reservationId)
      track('Tickets Showtime Selected', {
        ...trackingPayload,
      })
      track(`Tickets Showtime Selected ${theatricalRelease.theatricalSlug}`, {
        ...trackingPayload,
      })

      trackProductAdded({
        category: 'theatrical ticket',
        funnel: 'theatrical',
        sku: `ticket-${theatricalRelease?.theatricalSlug}`,
        product_id: `ticket-${theatricalRelease?.theatricalSlug}`,
        name: `${theatricalRelease?.theatricalSlug} theatrical ticket`,
        // ? if we could get the price for the showtime selected other wise this is
        // ! the value we are telling marketing the action is worth
        price: 15,
        currency: 'USD',
        quantity: 1,
        projectSlug: theatricalRelease?.theatricalSlug,
      })
      if (isSameDay(parseISO(showTime?.utcStartTime), new Date(2024, 5, 19))) {
        track('June 19th Showtime Selected', {
          ...trackingPayload,
        })
      }
      if (venue.ticketingProviderSlug === 'atom-tickets') {
        track('Integraged Tickets Showtime Selected', {
          ...trackingPayload,
        })
      } else {
        track('Non Integraged Tickets Showtime Selected', {
          ...trackingPayload,
        })
      }
      trackTicketIntent(analyticsPayload)
    },
    [
      analyticsPayload,
      reservationId,
      theatricalRelease.theatricalSlug,
      track,
      trackProductAdded,
      trackTicketIntent,
      venue.ticketingProviderSlug,
    ],
  )

  const handleAngelCheckoutNavigation = useCallback(
    (showTime: TheatricalShowtime) => {
      if (showTime?.reservable) {
        return push(paths.tickets.checkout.seatMap(theaterSlug, showTime?.ticketingId, reservationId))
      }
      return push(paths.tickets.checkout.seatQuantitySelection(theaterSlug, showTime?.ticketingId, reservationId))
    },
    [push, theaterSlug, reservationId],
  )

  const handleShowtimeClick = useCallback(
    async (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      showTime: TheatricalShowtime,
      hasWarnedLanguage?: boolean,
    ) => {
      event?.preventDefault()
      sessionStorage.setItem('venueId', venue?.id)

      const isInOtherLanguage = detectLanguageDifference(showTime)
      const skipLanguageWarning = !regionToWarnWrongLanguage.has(countryCode) || !!hasWarnedLanguage

      if (isInOtherLanguage && !skipLanguageWarning) {
        return handleLanguageWarning(setLocaleWarningModalState, showTime, () => {
          handleShowtimeClick(event, showTime, true)
        })
      }

      handleSendShowTimeClickEvents(showTime)

      if (isVenueIntegrated) return handleAngelCheckoutNavigation(showTime)
      promoContext?.handleOpenPromo({ event, promoContext, showTime, venue, trackingPayload: analyticsPayload })
    },
    [
      venue,
      detectLanguageDifference,
      countryCode,
      handleSendShowTimeClickEvents,
      isVenueIntegrated,
      handleAngelCheckoutNavigation,
      promoContext,
      analyticsPayload,
      handleLanguageWarning,
      setLocaleWarningModalState,
    ],
  )

  useEffect(() => {
    autoScrollToVenue({ venueId: venue?.id, venueRef: venueRef })
  }, [venue?.id])

  return (
    <li
      ref={venueRef}
      className={classNames(
        'relative flex flex-col rounded-xl border p-6 shadow-sm',
        isDarkMode ? 'bg-core-gray-900' : 'bg-white border-core-gray-200',
      )}
      style={{ zIndex }}
    >
      <div className="justify-between gap-3 md:flex lg:flex-col xl:flex-row xl:items-start">
        <FlexColumn className="w-full gap-1">
          <div className="flex !items-start">
            <GBFadeInExperiment className="shrink-0" experimentName="venue-logo" defaultValue={false}>
              <ExperimentVariation variation={true}>
                {venue?.vendorId && <VenueLogo className="mr-3 shrink-0" vendorId={venue.vendorId} />}
              </ExperimentVariation>
              <ExperimentVariation default>{null}</ExperimentVariation>
            </GBFadeInExperiment>
            <div>
              <FlexRow className="w-full !items-start justify-between gap-2">
                <Text
                  as={AsH3}
                  className="photon-title-sm md:photon-title-md flex flex-wrap items-center gap-1.5"
                  color={isDarkMode ? 'white' : 'black'}
                  weight="bold"
                >
                  {venue?.name}
                  {!isPhilippinesFreeTicketFlow && hasNoShowtimes && (
                    <span
                      className={classNames('font-light photon-title-xs', isDarkMode ? 'text-white' : 'text-black')}
                    >
                      {t('noShowtimesParenthesis', '(No Showtimes)')}
                    </span>
                  )}
                </Text>
              </FlexRow>
              <CaptionMD color={isDarkMode ? 'core-gray-400' : 'core-gray-700'}>
                {venue.addressText}
                {venuePhoneNumber && sortedShowTimes?.length === 0 && ` - ${venuePhoneNumber?.formatNational()}`}
              </CaptionMD>
            </div>
          </div>
          {/*{shouldShowFeaturedIcon && <CircleStarIcon className="absolute bottom-2 right-2" />}*/}
        </FlexColumn>
      </div>
      {!hasNoShowtimes && (
        <VenuePills
          theatricalSlug={theaterSlug}
          venue={venue}
          isAngelPartnerVenue={isAngelPartnerVenue}
          guildTicketsCount={guildTicketsCount}
          className="mt-6"
          noOnlineTicketing={!doShowtimesHavePurchaseUrl}
        />
      )}
      <ShowtimesList
        className="mt-6"
        countryCode={countryCode}
        venue={venue}
        reservationId={reservationId}
        theaterSlug={theaterSlug}
        isVenueIntegrated={isVenueIntegrated}
        showTimes={sortedShowTimes}
        venueId={venue?.id}
        onClick={handleShowtimeClick}
        shouldShowGroupTicketsButton={isAngelPartnerVenue && !!theatricalRelease?.region?.isGroupTicketsEnabled}
        shouldShowPrivateShowingButton={VALID_PRIVATE_SHOWING_SLUGS.has(theaterSlug)}
        venuePhoneNumber={venuePhoneNumber}
        analyticsPayload={analyticsPayload}
        openGroupTicketsModal={openGroupTicketsModal}
        openBuyoutModal={openBuyoutModal}
      />
      {hasNoShowtimes && !doShowtimesHavePurchaseUrl && !isPhilippinesFreeTicketFlow && (
        <ShowtimesWithNoUrlsState
          venue={venue}
          venuePhoneNumber={venuePhoneNumber}
          analyticsPayload={analyticsPayload}
        />
      )}
    </li>
  )
}
