import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { CaptionSM, EyebrowMD, HeadingXS, TitleLG, TitleSM } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { useTranslate } from '@/utils/translate/translate-client'
import { FlexibleBookingToolTip } from '../ToolTips/FlexibleBookingToolTip'
import { SpecificTimesToolTip } from '../ToolTips/SpecificTimesToolTip'

interface NoFeesModalProps {
  open: boolean
  onClose: () => void
  isManualCode?: boolean
  theatricalSlug?: string
  code: string
}
export const NoFeesModal = ({ open, onClose, code }: NoFeesModalProps) => {
  const { t } = useTranslate('tickets')
  const [copiedCode, setCopiedCode] = useState(false)

  const handleCopyCode = useCallback(() => {
    if (code != null) {
      navigator.clipboard.writeText(code)
      setCopiedCode(true)
    }
  }, [code])

  return (
    <Modal
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      backgroundOverlayClassName="!bg-black/85"
      panelClasses="!bg-[url(https://images.angelstudios.com/image/upload/v1727392065/bonhoeffer/bonhoeffer_early_bird_modal.jpg)] bg-bottom bg-cover !text-white !max-w-[380px] shadow-dark-4 rounded-3xl !p-6 !pt-10 relative text-center !overflow-visible"
      transition="appear"
      className="z-[99999]"
    >
      <div>
        <div className="absolute right-6 top-6 cursor-pointer rounded-full bg-white/20 p-1" onClick={onClose}>
          <CloseIcon color="white" size={18} />
        </div>
        <FlexColumn className="relative z-10 gap-6">
          <FlexColumn className="gap-1">
            <HeadingXS weight="bold" className="my-2 w-[70%] self-center">
              {t('noOnlineFeesNoHassleV1', 'No Online Fees. No Hassle.')}
            </HeadingXS>
            <FlexRow className="justify-center gap-1">
              <SpecificTimesToolTip>
                <TitleSM>{t('onSelectShowtimes', 'On Select Showtimes')}</TitleSM>
              </SpecificTimesToolTip>
            </FlexRow>
            <FlexibleBookingToolTip
              className="mx-auto w-fit"
              panelPosition="CENTER"
              buttonClassName={classNames(
                'backdrop-blur border-none w-full bg-white/20 backdrop-filter backdrop-blur-[24px]',
              )}
            />
          </FlexColumn>
          <FlexColumn className="w-fit self-center">
            <EyebrowMD className="text-[0.938rem] uppercase">{t('useCode', 'Use Code')}</EyebrowMD>
            <TitleLG className="my-1 w-fit self-center bg-[#F2A31E] px-3 py-2" weight="bold">
              {code}
            </TitleLG>
            <EyebrowMD className="text-[0.938rem] uppercase">{t('atCheckout', 'At Checkout')}</EyebrowMD>
          </FlexColumn>
          <FlexColumn className="items-center gap-3">
            <Button onClick={handleCopyCode} className="flex w-fit items-center gap-1" variant="white">
              {copiedCode ? (
                <CheckIcon size={24} color="core-gray-950" />
              ) : (
                <CopyIcon size={24} color="core-gray-950" />
              )}
              {copiedCode ? t('copiedCode', 'Copied Code') : t('copyCode', 'Copy Code')}
            </Button>
            <CaptionSM italic color="core-gray-400">
              {t('availableForAngelPartneringTheaters', 'Available for Angel Partnering Theaters.')}
            </CaptionSM>
          </FlexColumn>
        </FlexColumn>
      </div>
    </Modal>
  )
}
