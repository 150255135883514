import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsP, LabelXS, Text } from '@/atoms/Text'
import { paths, slugs } from '@/constants'
import { useRegionContext } from '@/contexts/RegionContext'
import { useExperimentContext } from '@/experimentation'
import { getWebClient } from '@/services/ApolloClient'
import { useUserGuildTickets } from '@/services/GuildUserService'
import { useTranslate } from '@/utils/translate/translate-client'

export const HomesteadGiveawayBanner: React.FC = () => {
  const { t } = useTranslate('tickets')

  return (
    <div className="relative bg-[#15120F] @container">
      <ResponsiveBackgroundImage
        src="/v1731110392/Homestead/homestead-banner-bg-fade"
        className="hidden xs:block"
        backgroundSize="bg-contain"
        backgroundPosition="bg-right"
        responsiveAR={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          '2xl': 2,
          '3xl': 2,
        }}
      />
      <div className="relative flex flex-col items-start gap-2 px-4 py-6 @2xl:flex-row @2xl:items-center">
        <div>
          <Text
            as={AsP}
            color="white"
            className="!font-[dharma-gothic-e] text-[1.625rem] font-bold uppercase leading-none tracking-wider"
          >
            {t('get100EntriesToTheGiveaway', 'Get 100 entries to the giveaway!')}
          </Text>
          <Text
            as={AsP}
            color="homestead-yellow"
            className="mb-[5px] !font-[dharma-gothic-e] text-[2rem] font-bold uppercase leading-none tracking-[0.06rem]"
          >
            {t('claimYourGuildTickets', 'Claim your Guild tickets')}
          </Text>
          <Link
            target="_blank"
            href={paths.preSales.giveaway('homestead')}
            className="font-[bio-sans] text-sm font-semibold leading-tight text-homestead-tan underline"
          >
            {t('learnMoreAboutTheGiveaway', 'Learn more about the Giveaway.')}
          </Link>
        </div>
        <LabelXS className="flex items-center gap-1 rounded-full bg-lightened-200 px-3 py-1 text-white backdrop-blur-xl">
          <CircleInfoIcon size={12} />
          {t('withAngelPartnerTheaters', 'With Angel Partner Theaters')}
        </LabelXS>
      </div>
    </div>
  )
}

export function useHomesteadGiveawayBanner(theatricalSlug: string) {
  const client = getWebClient()
  const { numberAvailable } = useUserGuildTickets({
    theatricalSlug: 'homestead',
    client,
  })
  const { region } = useRegionContext()
  const { getFeatureValue } = useExperimentContext()
  const { query } = useRouter()

  let shouldShowHomesteadGiveawayBanner = false

  // if in guild email blast flow, show the banner to all US  on the homestead
  // Otherwise, show the banner to users with guild tickets in an experiment
  if (query?.flow === 'guild-email-blast') {
    shouldShowHomesteadGiveawayBanner = theatricalSlug === slugs.homestead && region === 'US'
  } else {
    shouldShowHomesteadGiveawayBanner =
      theatricalSlug === slugs.homestead &&
      numberAvailable >= 2 &&
      region === 'US' &&
      getFeatureValue('homestead_giveaway_showtime_banner', false)
  }

  return { shouldShowHomesteadGiveawayBanner }
}
