import React from 'react'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { AppFeatures, GBFadeInExperiment } from '@/experimentation'
import { ExperimentVariation, Values } from '@/experimentation/components/ExperimentVariation'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal'
import { TheatricalTrailerExperimentType } from '@/services/CmsService/TheatricalTrailerExperiment'

interface TrailerModalProps {
  open: boolean
  onClose: () => void
  trailerUrl: string
  trailerExperiment?: TheatricalTrailerExperimentType
}
export const TrailerModal = ({ open, onClose, trailerUrl, trailerExperiment }: TrailerModalProps) => {
  return (
    <Modal
      backgroundOverlayClassName="!bg-black/90"
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      panelClasses="!bg-transparent min-w-[90%] relative !overflow-visible text-center p-2"
      transition="appear"
      className="z-[9999999]"
    >
      <div>
        <div className="absolute -top-8 right-2 cursor-pointer rounded-full bg-core-gray-200 p-1" onClick={onClose}>
          <CloseIcon color="black" size={18} />
        </div>
        <div>
          {trailerExperiment && (
            <GBFadeInExperiment
              defaultValue={false}
              experimentName={trailerExperiment?.experimentKey as keyof AppFeatures}
            >
              {trailerExperiment?.variants?.map((experiment) => {
                if (!experiment?.variantKey) return null

                return (
                  <ExperimentVariation
                    key={experiment.variantKey}
                    variation={experiment.variantKey as unknown as Values}
                  >
                    <EmbeddedVideo
                      iframeStyle={{ borderRadius: 10 }}
                      url={`${experiment?.trailer?.trailerUrl}?autoplay=1`}
                    />
                  </ExperimentVariation>
                )
              })}
              <ExperimentVariation default>
                <EmbeddedVideo iframeStyle={{ borderRadius: 10 }} url={`${trailerUrl}?autoplay=1`} />
              </ExperimentVariation>
            </GBFadeInExperiment>
          )}
          {!trailerExperiment && <EmbeddedVideo iframeStyle={{ borderRadius: 10 }} url={`${trailerUrl}?autoplay=1`} />}
        </div>
      </div>
    </Modal>
  )
}
