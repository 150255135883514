import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { FlexColumn } from '@/atoms/FlexContainers'
import { AsH2, Text } from '@/atoms/Text'
// import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { TheatricalReleaseObject, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { EnhancedShowtimeProvider } from '@/views/TicketCheckoutViews/ShowtimesView/components/EnhancedShowtimeContext'
import ExclusiveShowtimeDateWarning from '@/views/TicketCheckoutViews/ShowtimesView/components/ExclusiveShowtimeDateWarning'
import { SoldOutInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/SoldOutInfoIconTooltip'
import VenueList, { LocaleWarningModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueList'
import VenueListSkeleton from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueListSkeleton'
import BuyoutModal from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/BuyoutModal/BuyoutModal'
import { GroupTicketsModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'
import LocalWarningModal from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/LocalWarningModal'

interface Props {
  venues: TheatricalShowtimeVenue[]
  theatricalRelease: TheatricalReleaseObject
  isLoading: boolean
  groupTicketOptions: GroupTicketOption[]
  reservationId?: string
  isExclusiveDateSelected?: boolean
}
const Venues: FC<Props> = ({
  venues,
  theatricalRelease,
  isLoading,
  groupTicketOptions,
  reservationId,
  isExclusiveDateSelected,
}) => {
  const { t } = useTranslate('tickets')
  const { isReady, query, asPath, push } = useRouter()
  const [buyoutVenue, setBuyoutVenue] = useState<TheatricalShowtimeVenue | null>(null)
  const [buyoutVenueChain, setBuyoutVenueChain] = useState('')
  const [modalType, setModalType] = useState<GroupTicketsModalTypes>('tickets')
  const [isGroupTicketsModalOpen, setIsGroupTicketsModalOpen] = useState(false)
  const [isBuyoutModalOpen, setIsBuyoutModalOpen] = useState(false)
  const soldOut = theatricalRelease?.soldOutShowtimeCount ?? 0
  const { track } = useSafeAnalytics()

  const theaterSlug = theatricalRelease.theatricalSlug

  const venuesWithShowtimes = useMemo(() => {
    return venues?.filter((venue) => venue?.showtimes?.length > 0)
  }, [venues])

  const venuesWithNoShowtimes = useMemo(() => {
    return venues?.filter((venue) => venue?.showtimes?.length === 0)
  }, [venues])

  const openGroupTicketsModal = useCallback((type: GroupTicketsModalTypes) => {
    setIsGroupTicketsModalOpen(true)
    setModalType(type)
  }, [])

  const openBuyoutModal = useCallback((buyoutVenue: TheatricalShowtimeVenue, theaterChain: string) => {
    setIsBuyoutModalOpen(true)
    setBuyoutVenue(buyoutVenue)
    setBuyoutVenueChain(theaterChain)
  }, [])

  useEffect(() => {
    if (query?.groupCheckoutComplete === 'true' && !isGroupTicketsModalOpen) {
      track('Group Tickets Order Complete')
      push(asPath.replace('groupCheckoutComplete=true', ''))
      openGroupTicketsModal('group_ticket_email_sent')
    }
  }, [openGroupTicketsModal, track, query?.groupCheckoutComplete, isGroupTicketsModalOpen, push, asPath])

  useEffect(() => {
    if (query?.highlightGroupTickets) {
      openGroupTicketsModal('tickets')
    }
  }, [openGroupTicketsModal, query?.highlightGroupTickets])

  const [localeWarningModalState, setLocaleWarningModalState] = useState<LocaleWarningModalState>({
    isOpen: false,
    warning: '',
    callback: () => null,
  })

  const closeLocaleWarningModal = useCallback(() => {
    track('Canceled Showtime From Language Modal')
    setLocaleWarningModalState({ isOpen: false, warning: '', callback: () => null })
  }, [track])

  if (isLoading || !isReady) return <VenueListSkeleton />

  return (
    <EnhancedShowtimeProvider venues={venues}>
      <FlexColumn className="min-h-[50vh] gap-6 md:min-h-[55vh]">
        <FlexColumn className="gap-4">
          <FlexColumn className="gap-6">
            {isExclusiveDateSelected && <ExclusiveShowtimeDateWarning theatricalSlug={theaterSlug} />}
            {theatricalRelease?.soldOutShowtimeCount && theatricalRelease?.soldOutShowtimeCount > 19 && (
              <div className="inline-block rounded-lg border-l-4 !border-error-300 bg-error-100 px-2 py-3" role="alert">
                <Text as={AsH2} className="photon-caption-md md:photon-caption-lg">
                  <Translate i18nKey="soldOutShowtimesNationwide" t={t} values={{ soldOut }}>
                    <span className="photon-caption-xl font-semibold">{{ soldOut }}</span> sold out theaters nationwide.
                    Selling Fast! <SoldOutInfoIconTooltip />
                  </Translate>
                </Text>
              </div>
            )}
          </FlexColumn>
          <VenueList
            venues={venuesWithShowtimes}
            theatricalRelease={theatricalRelease}
            theaterSlug={theaterSlug}
            setLocaleWarningModalState={setLocaleWarningModalState}
            isGroupTicketsModalOpen={isGroupTicketsModalOpen}
            openGroupTicketsModal={openGroupTicketsModal}
            reservationId={reservationId}
            openBuyoutModal={openBuyoutModal}
            isBuyoutModalOpen={isBuyoutModalOpen}
          />
        </FlexColumn>

        {venuesWithNoShowtimes?.length > 0 && !isExclusiveDateSelected && (
          <FlexColumn className="gap-4">
            <Text as={AsH2} className="photon-title-md md:photon-title-lg" weight="bold">
              {t('noAvailableShowtimes', 'No Available Showtimes')}
            </Text>
            <VenueList
              venues={venuesWithNoShowtimes}
              theatricalRelease={theatricalRelease}
              theaterSlug={theaterSlug}
              setLocaleWarningModalState={setLocaleWarningModalState}
              isGroupTicketsModalOpen={isGroupTicketsModalOpen}
              openGroupTicketsModal={openGroupTicketsModal}
              reservationId={reservationId}
              openBuyoutModal={openBuyoutModal}
              isBuyoutModalOpen={isBuyoutModalOpen}
            />
          </FlexColumn>
        )}
      </FlexColumn>
      <LocalWarningModal onClose={closeLocaleWarningModal} localeWarningModalState={localeWarningModalState} />
      <GroupTicketsModal
        projectName={theatricalRelease?.title as string}
        theatricalSlug={theaterSlug}
        groupTicketOptions={groupTicketOptions}
        modalType={modalType}
        setModalType={setModalType}
        open={isGroupTicketsModalOpen}
        onClose={() => setIsGroupTicketsModalOpen(false)}
        shouldUseNewDesign
      />
      <BuyoutModal
        open={isBuyoutModalOpen}
        setOpen={setIsBuyoutModalOpen}
        buyoutVenue={buyoutVenue}
        venueChain={buyoutVenueChain}
        openGroupTicket={openGroupTicketsModal}
      />
    </EnhancedShowtimeProvider>
  )
}

export default Venues
