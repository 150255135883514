import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { FlexColumn } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { AppFeatures, ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { Values } from '@/experimentation/components/ExperimentVariation'
import { TheatricalTrailerExperimentType } from '@/services/CmsService/TheatricalTrailerExperiment'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import MobileTicketCtaCarousel from '@/views/TicketCheckoutViews/ShowtimesView/components/MobileTicketCtaCarousel'
import TicketGoalAndHypebot from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketGoalAndHypebot'
import TicketPosterTrailer from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketPosterTrailer'
import TicketSceneTrailer from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketSceneTrailer'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketsPayItForwardAd } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPayItForwardAd'
import { TrailerModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/TrailerModal'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'
import { GuildTicketsBanner } from './banners/GuildTicketsBanner'

interface TicketsHypebotPosterV2Props {
  containerRef?: React.RefObject<HTMLDivElement>
  isSceneModal?: boolean
  scrollToTop: () => void
  theatricalSlug: string
  theatricalRelease: TheatricalReleaseObject
  reservation?: ReservationObject | undefined
  trailerExperiment?: TheatricalTrailerExperimentType
  promo?: TheatricalPromoType
  projectGuildScore: {
    guildScore: number | undefined
  }
}
export const ShowtimesTrailerCTASection: React.FC<TicketsHypebotPosterV2Props> = ({
  containerRef,
  isSceneModal,
  scrollToTop,
  theatricalSlug,
  theatricalRelease,
  reservation,
  trailerExperiment,
  promo,
  projectGuildScore,
}) => {
  const { query } = useRouter()
  const { t } = useTranslate('tickets')
  const { discount } = useTicketsContext()
  const [isScenePlayerFullScreen, setIsScenePlayerFullScreen] = useState(!!isSceneModal)
  const [isTrailerModalOpen, setIsTrailerModalOpen] = useState(false)
  const handleOpenTrailerModal = useCallback(() => setIsTrailerModalOpen(true), [])
  const { areGuildTicketsAvailable, discountCodes } = useGuildTickets(theatricalSlug)
  const sceneId = query?.sceneId
  return (
    <>
      <aside
        aria-label={t('trailerSection', 'Trailer Section')}
        className={classNames('lg:h-full', !isSceneModal && 'relative lg:overflow-hidden')}
      >
        {!isSceneModal && (
          <>
            <div className="absolute h-full w-full overflow-hidden">
              <Image
                className="absolute inset-0 hidden h-full w-full scale-150 blur-xl md:block lg:w-auto lg:blur-lg"
                alt=""
                src={theatricalRelease?.verticalPoster ?? ''}
                width={400}
                height={400}
              />
            </div>
            <div className="absolute inset-0 hidden h-full bg-black/70 md:block" />
          </>
        )}
        <MobileTicketCtaCarousel
          theatricalRelease={theatricalRelease}
          reservation={reservation}
          promo={promo}
          theatricalSlug={theatricalSlug}
          scrollToTop={scrollToTop}
          className="md:hidden"
          discount={discount}
        />

        <div className="flex items-stretch justify-center md:mx-auto md:max-w-[975px] md:gap-4 md:p-4 lg:h-full lg:flex-col lg:justify-start">
          <FlexColumn className="z-[51] w-full gap-4">
            {sceneId ? (
              <TicketSceneTrailer
                isOpen={isScenePlayerFullScreen}
                setIsOpen={setIsScenePlayerFullScreen}
                scrollToTop={scrollToTop}
                containerRef={containerRef}
                sceneId={sceneId as string}
              />
            ) : (
              <>
                {trailerExperiment && (
                  <GBFadeInExperiment
                    defaultValue={'default'}
                    experimentName={trailerExperiment?.experimentKey as keyof AppFeatures}
                  >
                    {trailerExperiment?.variants?.map((experiment) => {
                      if (!experiment?.variantKey) return null
                      return (
                        <ExperimentVariation
                          key={experiment.variantKey}
                          variation={experiment.variantKey as unknown as Values}
                        >
                          <TicketPosterTrailer
                            trailer={experiment?.trailer?.trailerUrl as string}
                            thumbnail={`${experiment.thumbnail.item.public_id}.webp`}
                            theatricalRelease={theatricalRelease}
                            onOpenTrailer={handleOpenTrailerModal}
                            projectGuildScore={projectGuildScore}
                          />
                        </ExperimentVariation>
                      )
                    })}
                    <ExperimentVariation variation="default">
                      {query.promo === 'stop-hate' ? (
                        <TicketPosterTrailer
                          trailer="https://www.youtube.com/embed/7a1ifICBeZQ?si=Tr9u6nM7MvT-pZKg"
                          thumbnail="v1729780968/bonhoeffer/bonhoeffer-antiSem-vid-img.webp"
                          theatricalRelease={theatricalRelease}
                          onOpenTrailer={handleOpenTrailerModal}
                          projectGuildScore={projectGuildScore}
                        />
                      ) : (
                        <TicketPosterTrailer
                          trailer={theatricalRelease?.region?.trailerUrl as string}
                          thumbnail={theatricalRelease?.region?.trailerThumbnail as string}
                          theatricalRelease={theatricalRelease}
                          onOpenTrailer={handleOpenTrailerModal}
                          projectGuildScore={projectGuildScore}
                        />
                      )}
                    </ExperimentVariation>
                  </GBFadeInExperiment>
                )}
                {!trailerExperiment && (
                  <>
                    {query.promo === 'stop-hate' ? (
                      <TicketPosterTrailer
                        trailer="https://www.youtube.com/embed/7a1ifICBeZQ?si=Tr9u6nM7MvT-pZKg"
                        thumbnail="v1729780968/bonhoeffer/bonhoeffer-antiSem-vid-img.webp"
                        theatricalRelease={theatricalRelease}
                        onOpenTrailer={handleOpenTrailerModal}
                        projectGuildScore={projectGuildScore}
                      />
                    ) : (
                      <TicketPosterTrailer
                        trailer={theatricalRelease?.region?.trailerUrl as string}
                        thumbnail={theatricalRelease?.region?.trailerThumbnail as string}
                        theatricalRelease={theatricalRelease}
                        onOpenTrailer={handleOpenTrailerModal}
                        projectGuildScore={projectGuildScore}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </FlexColumn>
          {theatricalRelease?.hypebotCode && (
            <TicketGoalAndHypebot theatricalSlug={theatricalSlug} theatricalRelease={theatricalRelease} />
          )}
          <div>
            {areGuildTicketsAvailable && (
              <GuildTicketsBanner
                shouldUseGradient
                theatricalSlug={theatricalSlug}
                projectName={theatricalRelease?.title ?? ''}
                discountCodes={discountCodes}
                className="relative z-10 hidden overflow-hidden rounded-2xl lg:block"
              />
            )}
            {!areGuildTicketsAvailable && (
              <TicketsPayItForwardAd
                theatricalRelease={theatricalRelease}
                theatricalSlug={theatricalSlug}
                className="hidden lg:flex lg:flex-row"
              />
            )}
          </div>
        </div>
      </aside>
      {theatricalRelease?.region?.trailerUrl && (
        <TrailerModal
          open={isTrailerModalOpen}
          onClose={() => setIsTrailerModalOpen(false)}
          trailerUrl={
            query.promo === 'stop-hate'
              ? 'https://www.youtube.com/embed/7a1ifICBeZQ?si=Tr9u6nM7MvT-pZKg'
              : theatricalRelease?.region?.trailerUrl
          }
          trailerExperiment={trailerExperiment}
        />
      )}
    </>
  )
}
