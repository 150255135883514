import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { paths } from '@/constants/paths'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Seo } from '@/layout/Seo'
import { Captcha } from '@/services/Captcha'
import { TheatricalTrailerExperimentType } from '@/services/CmsService/TheatricalTrailerExperiment'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import DatadogRumFull from '@/utils/datadog/DatadogRumFull'
import { ShowtimesLayout } from '@/views/TicketCheckoutViews/ShowtimesView/components/ShowtimesLayout'
import { ShowtimesTrailerCTASection } from '@/views/TicketCheckoutViews/ShowtimesView/components/ShowtimesTrailerCTASection'
import { TicketsContextProvider } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketsPromoContextProvider } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/TicketsPromoContext'
import { ShareGuildTicketsModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/ShareGuildTickets'
import TicketDiscountCodesModal from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/TicketDiscountsModal'
import { useInitReservation } from '@/views/TicketCheckoutViews/hooks/useInitReservation'
import { getCodes, getDiscountCodes } from '@/views/TicketCheckoutViews/utils'
import { useSeoData } from './useSeoData'

interface Props {
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  groupTicketOptions: GroupTicketOption[]
  projectGuildScore: {
    guildScore: number | undefined
  }
  shouldUseDataDog?: boolean
  shouldUseNewDesign?: boolean
  isEditReservationFlow?: boolean
  trailerExperiment?: TheatricalTrailerExperimentType
  promo?: TheatricalPromoType
  isSceneModal?: boolean
}

export const ShowtimesView: FC<Props> = ({
  theatricalRelease,
  theatricalSlug,
  promo,
  groupTicketOptions,
  trailerExperiment,
  shouldUseDataDog = false,
  isEditReservationFlow = false,
  projectGuildScore,
  isSceneModal,
}) => {
  const [isShareGuildCodesModalOpen, setIsShareGuildCodesModalOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<HTMLDivElement>(null)
  const { query, asPath, isReady, push } = useRouter()
  const { reservation } = useInitReservation({ theatricalSlug })
  const shouldEmergencyRedirectToFandango =
    theatricalRelease?.region?.ticketsEmergencyRedirectUrl && theatricalRelease?.region?.redirectTicketsPage

  const seoData = useSeoData(theatricalRelease, asPath)
  const isGuildShare = query?.flow === 'guild-share'
  const isGiftFlow = query?.flow === 'guild-gift' || query?.flow === 'gift-tickets'

  const scrollToTop = useCallback(() => {
    calendarRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const discountCodes = useMemo(() => getDiscountCodes({ query }), [query])
  const codes = useMemo(() => getCodes({ query }), [query])

  useEffect(() => {
    if (shouldEmergencyRedirectToFandango && isReady) {
      push(theatricalRelease?.region?.ticketsEmergencyRedirectUrl as string)
    }
  }, [isReady, push, shouldEmergencyRedirectToFandango, theatricalRelease?.region?.ticketsEmergencyRedirectUrl])

  useEffect(() => {
    if (isGuildShare && discountCodes?.length > 0) {
      setIsShareGuildCodesModalOpen(true)
    }
  }, [discountCodes?.length, isGuildShare, query.flow])

  if (shouldEmergencyRedirectToFandango)
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinnerIcon size={40} />
      </div>
    )

  return (
    <>
      <Captcha />
      {shouldUseDataDog && <DatadogRumFull />}
      <Seo
        path={`${paths.tickets.index}/${theatricalSlug}`}
        canonical={seoData.url}
        description={seoData.description}
        openGraph={seoData}
        title={seoData.title}
      />
      <TicketsContextProvider
        promo={promo}
        reservation={reservation as ReservationObject}
        theatricalRelease={theatricalRelease}
        defaultSearchDate={new Date()}
      >
        <TicketsPromoContextProvider theatricalSlug={theatricalRelease.theatricalSlug}>
          <ThemeContextProvider isDarkMode={false}>
            <div
              ref={containerRef}
              className={classNames('absolute inset-0 !overflow-x-hidden lg:fixed top-0 md:!top-16')}
            >
              {!isEditReservationFlow && (
                <div className="relative lg:h-full lg:w-[476px]">
                  <div className="inset-0 lg:absolute ">
                    <ShowtimesTrailerCTASection
                      trailerExperiment={trailerExperiment}
                      reservation={reservation as ReservationObject}
                      theatricalRelease={theatricalRelease}
                      theatricalSlug={theatricalSlug}
                      containerRef={containerRef}
                      scrollToTop={scrollToTop}
                      promo={promo}
                      projectGuildScore={projectGuildScore}
                      isSceneModal={isSceneModal}
                    />
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  'bottom-0 flex flex-col lg:absolute lg:left-[476px] lg:right-0 lg:top-0',
                  isEditReservationFlow && '!left-0',
                )}
              >
                <ShowtimesLayout
                  promo={promo}
                  theatricalRelease={theatricalRelease}
                  reservation={reservation as ReservationObject}
                  theatricalSlug={theatricalSlug}
                  calendarRef={calendarRef}
                  scrollToTop={scrollToTop}
                  groupTicketOptions={groupTicketOptions}
                  isEditReservationFlow={isEditReservationFlow}
                  projectGuildScore={projectGuildScore}
                />
              </div>
              {discountCodes?.length > 0 && !isGuildShare && isGiftFlow && (
                <TicketDiscountCodesModal discounts={discountCodes} isGiftFlow={isGiftFlow} />
              )}
              {codes?.length > 0 && <TicketDiscountCodesModal isAutoApplied={false} discounts={codes} />}
              <ShareGuildTicketsModal
                open={isShareGuildCodesModalOpen}
                onClose={() => {
                  setIsShareGuildCodesModalOpen(false)
                }}
                theatricalSlug={theatricalSlug}
                projectName={theatricalRelease?.title as string}
                discountCodes={discountCodes}
              />
            </div>
          </ThemeContextProvider>
        </TicketsPromoContextProvider>
      </TicketsContextProvider>
    </>
  )
}
