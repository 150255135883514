import { useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { format, isAfter, startOfDay } from 'date-fns'
import { useRouter } from 'next/router'
import { useGBExperiment } from '@/experimentation'
import { createWebClient } from '@/services/ApolloClient'
import {
  TheatricalShowtime,
  TheatricalShowtimesByDayQuery,
  TheatricalShowtimesByDayQueryVariables,
  TheatricalShowtimeVenue,
} from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { getObjectFromLocalStorage } from '@/utils/local-storage'
import { useTranslate } from '@/utils/translate/translate-client'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { useAddressSearch } from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useAddressSearch'
import {
  getEarliestDate,
  isDateExpired,
  parseQueryDate,
  saveCoordinates,
  saveSearchDate,
} from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useVenueSearch/venueSearchUtils'
import { isQueryCoordinates } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'

export interface Coordinates {
  latitude: number
  longitude: number
}

const getVenueSeatsSold = (showtimes: TheatricalShowtime[] | undefined) => {
  if (!showtimes) return 0

  return showtimes.reduce((accumulator, { totalSeats, availableInventory }) => {
    if (!totalSeats || !availableInventory) return accumulator

    return accumulator + (totalSeats - availableInventory)
  }, 0)
}

export interface SavedSearchStateType {
  date: Date | null
  coordinates: Coordinates | null
  searchString: string | null
  expires: Date
}

export const theatricalShowtimesByDay = gql`
  query theatricalShowtimesByDay($input: TheatricalShowtimesByDayInput!) {
    theatricalShowtimesByDay(input: $input) {
      error
      searchLocation {
        city
      }
      venues {
        id
        name
        distance
        vendorId
        phone
        ticketingProviderSlug
        addressText
        address {
          line
          city
          countryCode
          zipCode
        }
        showtimes {
          id
          reservable
          utcStartTime
          localTimeOffset
          ticketUrl
          subtitleLanguage
          language
          availableInventory
          ticketingId
          totalSeats
        }
      }
    }
  }
`
const atomOnlyFlows = new Set(['guild', 'angel', 'preferred'])

interface Props {
  releaseDate: Date
  theatricalSlug: string
  countryCode: string
  onVenueChange?: () => void
  hasCalled?: boolean
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useVenueSearch = ({ releaseDate, theatricalSlug, onVenueChange, countryCode }: Props) => {
  const { track } = useSafeAnalytics()
  const { locale } = useLocale()
  const { query, isReady, replace, pathname } = useRouter()
  const { search } = useAddressSearch({ countryCode })
  const { isFreeTicketPromo } = useTicketsContext()
  const { t } = useTranslate('tickets')

  const [searchDate, setSearchDate] = useState<Date>()
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [hasSearchedOnce, setHasSearchedOnce] = useState(false)
  const [defaultAddressString, setDefaultAddressString] = useState('')

  const [shouldSortByMostSoldShowtimes] = useGBExperiment('venue-sold-out-sorting', false)

  const searchStorageKey = `angel-search-state-${theatricalSlug}`

  const shouldShowAtomVenuesOnly = useMemo(() => {
    return (
      query?.shouldShowAtomVenuesOnly === 'true' ||
      atomOnlyFlows?.has((query?.flow as string) ?? '') ||
      !!query?.discounts
    )
  }, [query?.discounts, query?.flow, query?.shouldShowAtomVenuesOnly])

  const client = useMemo(() => {
    return createWebClient({ locale, region: (query?.region as string)?.toUpperCase() ?? 'US' })
  }, [locale, query?.region])

  const { data, loading, error } = useQuery<TheatricalShowtimesByDayQuery, TheatricalShowtimesByDayQueryVariables>(
    theatricalShowtimesByDay,
    {
      variables: {
        input: {
          theatricalSlug,
          date: format(searchDate ?? new Date(), 'yyyy-MM-dd'),
          freeTicketCompatibleVenuesOnly: isFreeTicketPromo,
          includeVenuesWithoutShowtimes: shouldShowAtomVenuesOnly ? false : !isFreeTicketPromo,
          coords: coordinates,
          ticketingProviderSlug: shouldShowAtomVenuesOnly ? 'ATOM' : null,
        },
      },
      skip: !isInitialized || !searchDate || !client,
      onCompleted: (data) => {
        const venues = data?.theatricalShowtimesByDay?.venues
        if (venues) setHasSearchedOnce(true)
        const venuesWithShowtimes = venues?.filter((venue) => venue?.showtimes?.length > 0)
        track('Ticket Venues Searched', {
          venues,
          showtimesFound: venuesWithShowtimes && venuesWithShowtimes.length > 0,
        })
      },
      client,
    },
  )

  const filteredVenuesBySeatsSold = useMemo(() => {
    if (!data?.theatricalShowtimesByDay?.venues) return []

    const sortedVenues = [...data.theatricalShowtimesByDay.venues].sort((a, b) => {
      return (
        getVenueSeatsSold(b?.showtimes as TheatricalShowtime[]) -
        getVenueSeatsSold(a?.showtimes as TheatricalShowtime[])
      )
    })

    return (sortedVenues ?? []) as TheatricalShowtimeVenue[]
  }, [data?.theatricalShowtimesByDay?.venues])

  useEffect(() => {
    const ipCity = data?.theatricalShowtimesByDay?.searchLocation?.city
    if (ipCity) setDefaultAddressString(t('ipCityShowtimes', '{{ipCity}} Showtimes', { ipCity }))
  }, [data?.theatricalShowtimesByDay?.searchLocation?.city, t])

  useEffect(() => {
    if (data?.theatricalShowtimesByDay?.error === 'IP_LOCATION_NOT_FOUND' && window?.navigator?.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (geoLocation) =>
          setCoordinates({ latitude: geoLocation.coords.latitude, longitude: geoLocation.coords.longitude }),
        () => {
          setHasSearchedOnce(true)
        },
      )
    }
  }, [data?.theatricalShowtimesByDay?.error])

  const setDefaultLocation = useCallback(async () => {
    if (query?.location) {
      const queryLocation = query?.location as string
      const isCoordinates = isQueryCoordinates(queryLocation)

      if (isCoordinates) {
        const [latitude, longitude] = queryLocation.split(',')
        return setCoordinates({ latitude: parseFloat(latitude), longitude: parseFloat(longitude) })
      }

      const searchResult = await search(queryLocation)
      if (searchResult?.geometry?.coordinates) {
        setDefaultAddressString(searchResult?.properties?.label)
        return setCoordinates(searchResult.geometry.coordinates)
      }
    }

    const savedSearchState = getObjectFromLocalStorage<SavedSearchStateType>(searchStorageKey)

    if (savedSearchState && savedSearchState?.coordinates) {
      if (savedSearchState?.searchString) setDefaultAddressString(savedSearchState.searchString)
      if (!isDateExpired(savedSearchState?.expires)) return setCoordinates(savedSearchState.coordinates)
    }
  }, [query?.location, search, searchStorageKey])

  const setDefaultDate = useCallback(() => {
    const queryDate = query?.date && parseQueryDate(query?.date as string)

    if (queryDate) {
      const isQueryInPast = isAfter(startOfDay(new Date()), startOfDay(queryDate))
      if (!isQueryInPast) return setSearchDate(queryDate)
    }

    const savedSearchState = getObjectFromLocalStorage<SavedSearchStateType>(searchStorageKey)

    if (savedSearchState && savedSearchState.date) {
      if (!isDateExpired(savedSearchState?.expires)) return setSearchDate(new Date(savedSearchState.date))
    }

    setSearchDate(getEarliestDate(releaseDate))
  }, [query?.date, releaseDate, searchStorageKey])

  const setupDefaultSearchParameters = useCallback(async () => {
    setDefaultDate()
    await setDefaultLocation()
    setIsInitialized(true)
  }, [setDefaultDate, setDefaultLocation])

  useEffect(() => {
    if (!isReady || isInitialized) return

    setupDefaultSearchParameters()
  }, [
    setIsInitialized,
    isReady,
    searchStorageKey,
    setDefaultDate,
    setDefaultLocation,
    isInitialized,
    setupDefaultSearchParameters,
  ])

  const removeQueryParam = useCallback(
    (param: string) => {
      if (!query?.[param]) return

      delete query[param]
      replace(
        {
          pathname: pathname,
          query: query,
        },
        undefined,
        { shallow: true },
      )
    },
    [pathname, query, replace],
  )

  const handleAddressChange = useCallback(
    (coordinates: Coordinates, searchString: string) => {
      track('Tickets Search Submitted', {
        date: searchDate,
        coordinates,
        theatricalSlug: theatricalSlug,
        venues: data?.theatricalShowtimesByDay?.venues ?? [],
      })
      sessionStorage.removeItem('venueId')
      sessionStorage.setItem('ticketSearch', searchString)
      setCoordinates(coordinates)
      saveCoordinates(coordinates, searchString, searchStorageKey)
      removeQueryParam('location')
      onVenueChange?.()
    },
    [
      data?.theatricalShowtimesByDay?.venues,
      onVenueChange,
      removeQueryParam,
      searchDate,
      searchStorageKey,
      theatricalSlug,
      track,
    ],
  )

  const handleDateChange = useCallback(
    (date: Date) => {
      track('Tickets Search Submitted', {
        date,
        coordinates,
        theatricalSlug: theatricalSlug,
      })
      setSearchDate(date)
      saveSearchDate(date, searchStorageKey)
      removeQueryParam('date')
      onVenueChange?.()
    },
    [coordinates, onVenueChange, removeQueryParam, searchStorageKey, theatricalSlug, track],
  )
  return useMemo(() => {
    const venues = data?.theatricalShowtimesByDay?.venues ?? []

    return {
      earliestDate: getEarliestDate(releaseDate),
      searchDate,
      handleDateChange,
      handleAddressChange,
      defaultAddressString,
      loading,
      error,
      venues: shouldSortByMostSoldShowtimes ? filteredVenuesBySeatsSold : venues,
      hasSearchedOnce,
    }
  }, [
    data?.theatricalShowtimesByDay?.venues,
    releaseDate,
    searchDate,
    handleDateChange,
    handleAddressChange,
    defaultAddressString,
    loading,
    error,
    shouldSortByMostSoldShowtimes,
    filteredVenuesBySeatsSold,
    hasSearchedOnce,
  ])
}
