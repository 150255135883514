import { FC } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'

interface Props {
  vendorId: string | undefined
  className?: string
}

const venueLogoMap: Record<string, string> = {
  CINEMARK: '/v1731548545/angel-studios/tickets/Venue%20Logos/cinemark-logo.webp',
  HARKINS: '/v1731548546/angel-studios/tickets/Venue%20Logos/harkins-theaters-logo.webp',
  MEGAPLEX_MX: '/v1731548795/angel-studios/tickets/Venue%20Logos/megaplex-theater-logo.webp',
  REGAL: '/v1731548550/angel-studios/tickets/Venue%20Logos/regal-cinemas-logo.webp',
  REGAL_MX: '/v1731548550/angel-studios/tickets/Venue%20Logos/regal-cinemas-logo.webp',
  AMC: '/v1731548549/angel-studios/tickets/Venue%20Logos/amc-theaters-logo.webp',
  BB_MX: '/v1731548549/angel-studios/tickets/Venue%20Logos/b_b-theaters-logo.webp',
  COBB: '/v1731548548/angel-studios/tickets/Venue%20Logos/cmx-cinemas-logo.webp',
  CINEPOLIS: '/v1731548547/angel-studios/tickets/Venue%20Logos/cinepolis-theaters-logo.webp',
  EMAGINE: '/v1731706150/angel-studios/tickets/Venue%20Logos/emagine-logomark.svg',
}

const getVenueLogoUrl = (vendorId: string | undefined) => {
  if (!vendorId) return null
  return venueLogoMap[vendorId] ?? null
}

export const VenueLogo: FC<Props> = ({ vendorId, className }) => {
  const logoUrl = getVenueLogoUrl(vendorId)

  if (!logoUrl) return null

  return (
    <Image className={classNames('rounded-xl', className)} src={logoUrl} width={48} height={48} alt="Theater Logo" />
  )
}
