import React, { FC, useMemo, useState } from 'react'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { AsH2, CaptionMD, TitleSM } from '@/atoms/Text'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { SimpleThumbnail } from '@/molecules/Thumbnail'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { mergeQueryParameters } from '@/utils/UriBuilder'
import TicketMovieDetails from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketMovieDetails'
import { TicketsPayItForwardAd } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPayItForwardAd'
import TrailerSection from '@/views/TicketCheckoutViews/ShowtimesView/components/TrailerSection'
import { getHoursMinutes } from '@/views/TicketCheckoutViews/utils'

interface TicketPosterTrailerProps {
  onOpenTrailer: () => void
  theatricalRelease: TheatricalReleaseObject
  trailer: string
  thumbnail: string
  projectGuildScore: {
    guildScore: number | undefined
  }
}
const TicketPosterTrailer: FC<TicketPosterTrailerProps> = ({
  onOpenTrailer,
  theatricalRelease,
  trailer,
  thumbnail,
  projectGuildScore,
}) => {
  const { locale } = useLocale()
  const [loadPlayer, setLoadPlayer] = useState(false)

  const trailerUrl = useMemo(() => {
    if (!trailer) return ''
    if (!thumbnail) return trailer
    return mergeQueryParameters(trailer, { autoplay: '1' })
  }, [thumbnail, trailer])

  const handleLoadPlayer = () => {
    setLoadPlayer(!loadPlayer)
  }
  const duration = useMemo(() => {
    if (!theatricalRelease?.movieDuration) return null
    return getHoursMinutes(theatricalRelease?.movieDuration / 60, locale)
  }, [locale, theatricalRelease?.movieDuration])

  return (
    <FlexColumn className="z-10 h-full gap-4">
      <div className="z-10 md:rounded-2xl md:bg-white/10 md:p-4">
        <>
          <div className="hidden lg:block">
            {trailer && !loadPlayer && thumbnail && (
              <SimpleThumbnail className="rounded-xl" showButton imageUrl={thumbnail} onClick={handleLoadPlayer} />
            )}
            {loadPlayer && (
              <div className="overflow-hidden rounded-xl">
                <EmbeddedVideo className="rounded-xl" title="Official Theatrical Trailer" url={trailerUrl} />
              </div>
            )}
          </div>
        </>
        <FlexColumn className="hidden gap-1 lg:flex">
          <FlexRow className="mt-6 flex-wrap justify-between gap-1 pb-2 text-white">
            <FlexColumn className="gap-2">
              <TitleSM as={AsH2} weight="bold">
                {theatricalRelease?.title}
              </TitleSM>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexColumn className="hidden gap-1 pb-2 lg:flex">
          <FlexRow>
            <TicketMovieDetails
              duration={duration}
              theatricalRelease={theatricalRelease}
              projectGuildScore={projectGuildScore}
            />
          </FlexRow>
        </FlexColumn>
        <CaptionMD color="core-gray-300" className="hidden pb-2 lg:block">
          {theatricalRelease?.shortDescription}
        </CaptionMD>

        <TrailerSection
          duration={duration as string}
          className="!bg-transparent md:overflow-visible md:!p-0 lg:hidden"
          theatricalRelease={theatricalRelease}
          onTrailerClick={onOpenTrailer}
          projectGuildScore={projectGuildScore}
        />
      </div>
      <TicketsPayItForwardAd
        theatricalRelease={theatricalRelease}
        theatricalSlug={theatricalRelease?.theatricalSlug}
        className="hidden md:flex lg:hidden"
      />
    </FlexColumn>
  )
}

export default TicketPosterTrailer
