import React, { FC } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { CaptionMD, CaptionXS, Text, TitleMD } from '@/atoms/Text'
import { GBFadeInExperiment, ExperimentVariation } from '@/experimentation'
import { useTranslate } from '@/utils/translate/translate-client'
import { AntisemitesToolTip } from '../../ToolTips/AntisemitesToolTip'

interface Props {
  className?: string
  calendarRef?: React.RefObject<HTMLDivElement>
}

export const AntisemitePromoBanner: FC<Props> = ({ className, calendarRef }) => {
  const { t } = useTranslate('tickets')

  const scrollToCalendar = () => {
    if (calendarRef?.current) {
      calendarRef.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      const calendarElement = document.getElementById('calendar')
      if (calendarElement) {
        calendarElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const handleFreeTicketClick = () => {
    window.open('/tickets/bonhoeffer?promo=claim-free-ticket', '_self')
  }

  return (
    <div
      className={classNames(
        'relative z-[49] bg-cover bg-left bg-no-repeat @sm:max-h-[160px] @2xl:max-h-[120px] bg-[#501519] @container flex items-center justify-center py-[17.5px]',
        className,
      )}
    >
      <FlexColumn className="items-center">
        <GBFadeInExperiment experimentName="antisemite_banner_copy_test" defaultValue="original">
          <ExperimentVariation variation="original">
            <TitleMD className="mb-[5px] text-center uppercase" weight="bold" color="white">
              {t('freeTicketsForAntisemitesv3', "The Free Tickets you did 'nazi' coming")}
            </TitleMD>
          </ExperimentVariation>
          <ExperimentVariation variation="direct">
            <TitleMD className="mb-[5px] text-center uppercase" weight="bold" color="white">
              {t('freeTicketsForAntisemitesv3Direct', 'Free Tickets for Antisemites*')}
            </TitleMD>
          </ExperimentVariation>
          <ExperimentVariation variation="subtle">
            <TitleMD className="mb-[5px] text-center uppercase" weight="bold" color="white">
              {t('freeTicketsForAntisemitesv3Subtle', "The Free Tickets you did 'not-see' coming")}
            </TitleMD>
          </ExperimentVariation>
        </GBFadeInExperiment>
        <AntisemitesToolTip buttonClassName="focus-visible:outline-none focus-visible:ring-offset-transparent focus-visible:ring-transparent" />
        <FlexRow className="my-3 gap-2 md:mt-3 md:gap-6">
          <Button
            variant="white"
            className="flex flex-col !border-[#F29F3D] !bg-[#F29F3D] px-3 hover:!border-[#F29F3D] hover:!bg-[#F29F3D]/90 @xl:px-5"
            onClick={handleFreeTicketClick}
          >
            <Text className="photon-title-xxs text-nowrap !font-bold uppercase tracking-[-0.5px]">
              {t('requestFreeTicketV2', 'Request a free ticket')}
            </Text>
            <CaptionXS italic>{t('imAnAntisemite', "I'm an antisemite")}</CaptionXS>
          </Button>
          <CaptionMD className="hidden @xl:block" color="white" italic>
            or
          </CaptionMD>
          <Button
            variant="white"
            className="flex flex-col !border-[#F29F3D] !bg-[#F29F3D] px-3 hover:!border-[#F29F3D] hover:!bg-[#F29F3D]/90 @xl:px-5"
            onClick={scrollToCalendar}
          >
            <Text className="photon-title-xxs text-nowrap !font-bold uppercase tracking-[-0.5px]">
              {t('buyTicket', 'Buy a ticket')}
            </Text>
            <CaptionXS className="text-nowrap" italic>
              {t('fightAntisemitism', 'I want to fight antisemitism')}
            </CaptionXS>
          </Button>
        </FlexRow>
      </FlexColumn>
      <div className="absolute right-0 top-0 -mr-3 hidden h-full w-[250px] items-stretch !p-0 @4xl:block">
        <div style={{ background: 'linear-gradient(90deg, #501519 0%, rgba(80, 21, 25, 0) 100%)' }}>
          <Image
            src="v1729777056/bonhoeffer/bonhoeffer-poster-wide-red-02.webp"
            alt="Bonhoeffer"
            className="h-full w-full object-cover"
            fill={true}
          />
        </div>
      </div>
    </div>
  )
}
